import { PhoneNumber, PostalCode } from "@/@types/reservation";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import japaneseHolidays from "japanese-holidays";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function deleteNullProp(obj: any): any {
  if (obj["facility_id"]) {
    delete obj["facility_id"];
  }
  if (obj["last_updated_by"]) {
    delete obj["last_updated_by"];
  }
  if (obj["data_type"]) {
    delete obj["data_type"];
  }
  if (obj["id"]) {
    delete obj["id"];
  }
  if (obj["updated_at"]) {
    delete obj["updated_at"];
  }
  if (obj["created_at"]) {
    delete obj["created_at"];
  }
  for (const k in obj) {
    if (obj[k] === null) {
      delete obj[k];
    }
  }
  return obj;
}
export function deleteNoChangeReservationProp(before: any, after: any): any {
  delete after["pet_ids"];
  delete after["patient_ids"];
  for (const k in after) {
    if (after[k] === before[k]) {
      delete after[k];
    }
  }
  return after;
}

export function getAge(year: number, month: number, date: number): number {
  //今日
  const today = new Date();

  //今年の誕生日
  const thisYearsBirthday = new Date(today.getFullYear(), month - 1, date);

  //年齢
  let age = today.getFullYear() - year;

  if (today < thisYearsBirthday) {
    //今年まだ誕生日が来ていない
    age--;
  }

  return age;
}

export function parsePhoneNumber(phone: string): string {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const parsedNumber = phoneUtil.parse(phone, "JP");
  return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
}

export function getPhoneNumber3(phone: string): PhoneNumber {
  const phoneNumber = phone;
  const number1 = phoneNumber.substring(0, phoneNumber.indexOf("-"));
  const replace1 = phoneNumber.replace(`${number1}-`, "");
  const number2 = replace1.substring(0, replace1.indexOf("-"));
  const replace2 = replace1.replace(`${number2}-`, "");
  const number3 = replace2;
  return { number1, number2, number3 };
}

export function getPostalCode2(code: string): PostalCode {
  const postalCode = code;
  const number1 = postalCode.substring(0, postalCode.indexOf("-"));
  const replace1 = postalCode.replace(`${number1}-`, "");
  const number2 = replace1;
  return { number1, number2 };
}

export const checkHoliday = (date: Date) => {
  return japaneseHolidays.isHoliday(date);
};

/**
 * forEach Promise化して実行
 *
 * @param array
 * @param callback
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

/**
 *
 * map Promise化して実行
 * @param array
 * @param operation
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function asyncMap(array: any[], operation: any) {
  // eslint-disable-next-line no-return-await
  return Promise.all(array.map(async (item) => await operation(item)));
}
