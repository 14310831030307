
import { Component } from "vue-property-decorator";
import Calendar from "@/components/calendar/Calendar.vue";
import HospitalHeader from "@/components/HospitalHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";

@Component({
  components: {
    Calendar,
    HospitalHeader,
    ManagementFooter,
  },
})
export default class Schedule extends CheckCommonLogin {}
