
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BirthdaySelect extends Vue {
  private form = {
    year: 0,
    month: null,
    day: null,
  };

  private mounted() {
    // this.changeTargetForm();
  }

  private get years() {
    const years = [];
    for (let year = 1900; year <= new Date().getFullYear(); year++) {
      years.push(year);
    }
    return years;
  }
  private get months() {
    const months = [...Array(12)].map((ele, i) => i + 1);
    return months;
  }

  private get days() {
    let days = [];
    if (
      (this.form.month === 2 && this.form.year % 4 === 0 && this.form.year % 100 !== 0) ||
      (this.form.month === 2 && this.form.year % 400 === 0)
    ) {
      days = [...Array(29)].map((ele, i) => i + 1);
    } else if (this.form.month === 2) {
      days = [...Array(28)].map((ele, i) => i + 1);
    } else if (this.form.month === 4 || this.form.month === 6 || this.form.month === 9 || this.form.month === 11) {
      days = [...Array(30)].map((ele, i) => i + 1);
    } else {
      days = [...Array(31)].map((ele, i) => i + 1);
    }
    return days;
  }
}
