
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import moment from "moment";
import BirthdaySelect from "@/components/forms/components/BirthdaySelect.vue";
import FacilityService from "@/services/facilities";
import MemberService from "@/services/members";
import { CustomerData, PetData, ReservationData } from "@/@types/reservation";
import { CourseData, CourseDetail } from "@/@types/course";
import { asyncMap, asyncForEach, checkHoliday } from "@/utils/Util";
import _ from "lodash";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";

@Component({
  components: {
    FullCalendar,
    BirthdaySelect,
    Button,
    FormSet,
  },
})
export default class DayCalendar extends Vue {
  @Prop({ type: Object, default: null })
  private readonly course!: CourseData;

  @Prop({ type: String, default: "" })
  private readonly currentDate!: string;

  @Prop({ type: String, default: "" })
  private readonly hospitalId!: string;

  private reservation = [];

  private reservationsWithXy: any = [];

  private nonBusinessHoursWithXy: any = [];

  private isCreatedReservationsData = false;

  private selectedReservation: any = null;

  private isLoading = false;

  private outputCsvReservations: any = [];

  private backgroundArray = ["", "", "", "", ""];

  private x = 1025;
  private y = 50;

  private nowHeight = 0;

  private getReservationClass(dataType: string) {
    if (dataType === "reservation_special") {
      return "reservation-special";
    } else if (dataType === "reservation_group") {
      return "reservation-group";
    } else {
      return "";
    }
  }

  private getNowHeight() {
    const now: Date = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    this.nowHeight = (hour * 60 + minute) * 3.33333333;
  }

  private getNowHeightIntervalId: any = null;

  private async mounted() {
    this.getNowHeight();
    this.getNowHeightIntervalId = setInterval(this.getNowHeight, 60000);
    await this.getReservations();
    if (this.$refs.scrollWrap) {
      (this.$refs.scrollWrap as HTMLElement).scrollTop = this.nowHeight - 50;
    }
  }

  private beforeDestroy() {
    clearInterval(this.getNowHeightIntervalId);
  }
  private get timelineArray() {
    const timeline = [];
    for (let i = 0; i < 96; i++) {
      if (i === 0) {
        timeline.push("0:00");
      } else if (i % 4 === 0) {
        timeline.push(`${i / 4}:00`);
      } else {
        timeline.push("");
      }
    }
    return timeline;
  }

  private getClassColor(index: number, isMain: boolean) {
    const colors = ["blue", "green", "red", "purple", "indigo"];
    return `${isMain ? "main" : "sub"}-${colors[index]}`;
  }

  private displayTimeDetail(reservation: any) {
    const weekdaysShort = ["日", "月", "火", "水", "木", "金", "土"];
    const formatDate = moment(reservation.datetime);
    const endFormatDate =
      reservation.duration_min !== undefined
        ? moment(reservation.datetime).add(reservation.duration_min, "minutes")
        : moment(reservation.datetime).add(reservation.course.duration_minute, "minutes");
    const m = formatDate.month() + 1;
    const d = formatDate.date();
    const day = formatDate.day();
    const h = formatDate.format("HH");
    const minute = formatDate.format("mm");
    const endH = endFormatDate.format("HH");
    const endMinute = endFormatDate.format("mm");
    return `${m}/${d} (${weekdaysShort[day]}) ${h}:${minute} 〜 ${endH}:${endMinute}`;
  }

  private displayTime(reservation: any) {
    const formatDate = moment(reservation.datetime);
    const endFormatDate = reservation.duration_min
      ? moment(reservation.datetime).add(reservation.duration_min, "minutes")
      : moment(reservation.datetime).add(reservation.course.duration_minute, "minutes");
    const h = formatDate.format("HH");
    const minute = formatDate.format("mm");
    const endH = endFormatDate.format("HH");
    const endMinute = endFormatDate.format("mm");
    return `${h}:${minute} 〜 ${endH}:${endMinute}`;
  }

  private displayBirthday(birthday: string) {
    if (birthday) {
      let displayBirthday = "";
      const year = birthday.substr(0, 4);
      const month = birthday.substr(4, 2);
      const date = birthday.substr(6, 2);
      if (year != "0000") {
        displayBirthday += `${year}年`;
      }
      if (month != "00") {
        displayBirthday += `${month}月`;
      }
      if (date != "00") {
        displayBirthday += `${date}日`;
      }
      return displayBirthday;
    } else {
      return "";
    }
  }

  private detailReservationName() {
    if (!this.selectedReservation) {
      return "";
    }
    const last_name = this.selectedReservation.customer_id
      ? this.selectedReservation.customer_last_name ?? ""
      : this.selectedReservation.member_info.last_name ?? "";
    const first_name = this.selectedReservation.customer_id
      ? this.selectedReservation.customer_first_name ?? ""
      : this.selectedReservation.member_info.first_name ?? "";
    const last_name_kana = this.selectedReservation.customer_id
      ? this.selectedReservation.customer_last_name_kana ?? ""
      : this.selectedReservation.member_info.last_name_kana ?? "";
    const first_name_kana = this.selectedReservation.customer_id
      ? this.selectedReservation.customer_first_name_kana ?? ""
      : this.selectedReservation.member_info.first_name_kana ?? "";
    return {
      last_name,
      first_name,
      last_name_kana,
      first_name_kana,
    };
  }

  private displayReservationInfoName(item: any) {
    const name = item.customer_last_name ? item.customer_last_name : item.member_info.last_name;
    const nameKana = item.customer_last_name ? item.customer_last_name_kana : item.member_info.last_name_kana;
    return `${name ? name : `(${nameKana})`}`;
  }
  private displayReservationInfoPetName(item: any) {
    let petName = item.pet_name ? item.pet_name : "";
    if (!petName) petName = item.patient_name ? item.patient_name : "";
    let petNameKana = item.pet_name ? item.pet_name_kana : "";
    if (!petNameKana) petNameKana = item.patient_name ? item.patient_name_kana : "";
    return `${petName ? petName : `(${petNameKana})`}`;
  }
  private displayReservationInfoType(item: any) {
    let petType = item.pet_animal_type ? item.pet_animal_type : "";
    if (!petType) petType = item.patient_animal_type ? item.patient_animal_type : "";
    return `${petType}`;
  }

  private toReservationEditPage(id: string) {
    this.$router.push({ name: "EditReservation", params: { id } });
  }

  private openReservationDetail(reservation: ReservationData) {
    this.selectedReservation = reservation;
    this.showModal("detail-info");
  }

  // [{courseIndex: 0, startHour: 0, startMinute: 0, endHour: 9, endMinute:0},{courseIndex: 0, startHour: 17, startMinute: 0, endHour: 24, endMinute:0}]

  private getViewDaysOfWeek() {
    const weekdaysShort = ["日", "月", "火", "水", "木", "金", "土"];
    const daysOfWeek = moment(this.currentDate ? new Date(this.currentDate) : new Date()).weekday();
    return weekdaysShort[daysOfWeek];
  }

  private setDayViewBusinessHour() {
    this.nonBusinessHoursWithXy = [];
    const results: any[] = [];
    const daysOfWeek = moment(this.currentDate ? new Date(this.currentDate) : new Date()).weekday();
    let stringDaysOfWeek = "";
    if (daysOfWeek === 0) {
      stringDaysOfWeek = "Sun";
    } else if (daysOfWeek === 1) {
      stringDaysOfWeek = "Mon";
    } else if (daysOfWeek === 2) {
      stringDaysOfWeek = "Tue";
    } else if (daysOfWeek === 3) {
      stringDaysOfWeek = "Wed";
    } else if (daysOfWeek === 4) {
      stringDaysOfWeek = "Thu";
    } else if (daysOfWeek === 5) {
      stringDaysOfWeek = "Fri";
    } else if (daysOfWeek === 6) {
      stringDaysOfWeek = "Sat";
    }
    this.course.details.forEach((courseDetail: CourseDetail) => {
      //コースが期間内かチェックcurrentDateがコースの期間内かどうか
      const startDate = new Date(
        courseDetail.period.start_year,
        courseDetail.period.start_month - 1,
        courseDetail.period.start_date
      );
      let isEndDateSet = false;
      if (courseDetail.period.end_year && courseDetail.period.end_month && courseDetail.period.end_date) {
        isEndDateSet = true;
      }
      const endDate = isEndDateSet
        ? new Date(courseDetail.period.end_year!, courseDetail.period.end_month! - 1, courseDetail.period.end_date!)
        : null;
      if (this.currentDate) {
        const currentDate = new Date(this.currentDate);
        // currentDateの時間を0にする
        currentDate.setHours(0, 0, 0, 0);
        if (currentDate < startDate || (endDate && currentDate > endDate)) {
          return;
        }
      }
      courseDetail.days.forEach((day) => {
        if (day.is_holiday_primary) {
          if (checkHoliday(this.currentDate ? new Date(this.currentDate) : new Date())) stringDaysOfWeek = "Holiday";
        }

        // 稼働曜日の場合
        if (day.available_days.includes(stringDaysOfWeek)) {
          let { times } = day;
          times.sort((a, b) => {
            const firstStartHour = a.on_time.start_hour;
            const secondStartHour = b.on_time.start_hour;
            return firstStartHour - secondStartHour;
          });
          // check if there is only one open/close hour
          let oneTime = times.length === 1;
          // get open and close hours given the times
          for (let i = 0; i < times.length; i++) {
            let openingHour = times[i].on_time.start_hour;
            let openingMinute = times[i].on_time.start_minute;
            let closingHour = times[i].on_time.end_hour;
            let closingMinute = times[i].on_time.end_minute;
            // push the closed hours array element
            if (i === 0) {
              results.push({
                startHour: 0,
                startMinute: 0,
                endHour: openingHour,
                endMinute: openingMinute,
              });
            }
            // if only one open/close hour
            if (oneTime) {
              // push the closed hours array element
              results.push({
                startHour: closingHour,
                startMinute: closingMinute,
                endHour: 24,
                endMinute: 0,
              });
            }
            // else two or more open/close hours
            else {
              // check if not the last indicated hour
              if (i < times.length - 1) {
                // get next open hour
                let nextOpeningHour = times[i + 1].on_time.start_hour;
                let nextOpeningMinute = times[i + 1].on_time.start_minute;
                // push the closed hours array element
                results.push({
                  startHour: closingHour,
                  startMinute: closingMinute,
                  endHour: nextOpeningHour,
                  endMinute: nextOpeningMinute,
                });
              }
              // last indicated hour
              else {
                // push the closed hours array element
                results.push({
                  startHour: closingHour,
                  startMinute: closingMinute,
                  endHour: 24,
                  endMinute: 0,
                });
              }
            }
          }
        }
      });
    });
    if (results.length === 0) {
      // 稼働日じゃない場合
      const width = this.x;
      const height = this.y * 96;
      const positionX = 0;
      const positionY = 0;
      this.nonBusinessHoursWithXy.push({ width, height, positionX, positionY });
    }
    const nonBusinessHours = results;
    // 営業時間外の配列作成
    nonBusinessHours.forEach((value: any) => {
      const width = this.x;
      const height = Math.round(
        ((value.endHour * 60 + value.endMinute - (value.startHour * 60 + value.startMinute)) / 15) * this.y
      );
      const positionX = 0;
      const positionY = Math.round(
        this.y *
          (((value.startHour * 60 + value.startMinute) / this.course.duration_minute) *
            (this.course.duration_minute / 15))
      );

      this.nonBusinessHoursWithXy.push({ width, height, positionX, positionY });
    });
  }

  // private getDynamicData(data: any) {
  //   const newData: any = [];
  //   console.log("data", newData);
  //   for (let i = 0; i < data.length; i++) {
  //     const { index, startHour, startMinute, endHour, endMinute } = data[i];
  //     let currentStartHour = startHour;
  //     let currentStartMinute = startMinute;

  //     while (currentStartHour < endHour) {
  //       if (
  //         !newData.find((time: any) => time.index === index && time.endHour === endHour && time.endMinute === endMinute)
  //       )
  //         newData.push({ index, startHour: currentStartHour, startMinute: currentStartMinute, endHour, endMinute });
  //       console.log("data", currentStartHour, newData);
  //       currentStartHour++;
  //     }
  //   }

  //   return newData;
  // }

  private getSpecialReservationTime(reservation: ReservationData) {
    const datetime = new Date(reservation.datetime);
    const minute = datetime.getHours() * 60 + datetime.getMinutes();
    return minute;
  }

  private generateNormalReservationView(
    reservations: ReservationData[],
    course: CourseData,
    yIndex: number,
    shift: number
  ) {
    const normalLength = reservations.length + shift;
    if (normalLength === 1) {
      // 予約が同時刻1つの場合
      reservations.forEach((reservation: any) => {
        reservation.width = Math.round(this.x / 5);
        reservation.height = Math.round((this.y * 4) / (60 / course.duration_minute));
        reservation.positionX = shift ? Math.round(this.x / 5) : 0;
        reservation.positionY = Math.round(this.y * yIndex);
        this.reservationsWithXy.push(reservation);
      });
    } else if (normalLength === 2) {
      // 予約が同時刻2つの場合
      reservations.forEach((reservation: any, index: number) => {
        reservation.width = Math.round(this.x / 5);
        reservation.height = Math.round((this.y * 4) / (60 / course.duration_minute));
        const viewIndex = index + shift;
        reservation.positionX = viewIndex ? Math.round(this.x / 5) * viewIndex : 0;
        reservation.positionY = Math.round(this.y * yIndex);
        this.reservationsWithXy.push(reservation);
      });
    } else if (normalLength === 3) {
      // 予約が同時刻3つの場合
      reservations.forEach((reservation: any, index: number) => {
        reservation.width = Math.round(this.x / 5);
        reservation.height = Math.round((this.y * 4) / (60 / course.duration_minute));
        const viewIndex = index + shift;
        reservation.positionX = viewIndex ? Math.round(this.x / 5) * viewIndex : 0;
        reservation.positionY = Math.round(this.y * yIndex);
        this.reservationsWithXy.push(reservation);
      });
    } else if (normalLength === 4) {
      // 予約が同時刻4つの場合
      reservations.forEach((reservation: any, index: number) => {
        reservation.width = Math.round(this.x / 5);
        reservation.height = Math.round((this.y * 4) / (60 / course.duration_minute));
        const viewIndex = index + shift;
        reservation.positionX = viewIndex ? Math.round(this.x / 5) * viewIndex : 0;
        reservation.positionY = Math.round(this.y * yIndex);
        this.reservationsWithXy.push(reservation);
      });
    } else if (normalLength === 5) {
      // 予約が同時刻5つの場合
      reservations.forEach((reservation: any, index: number) => {
        reservation.width = Math.round(this.x / 5);
        reservation.height = Math.round((this.y * 4) / (60 / course.duration_minute));
        const viewIndex = index + shift;
        reservation.positionX = viewIndex ? Math.round(this.x / 5) * viewIndex : 0;
        reservation.positionY = Math.round(this.y * yIndex);
        this.reservationsWithXy.push(reservation);
      });
    } else if (normalLength > 5) {
      // 予約が同時刻6つ以上の場合
      const overflowReservations: any[] = []; // 4つ目以降の予約を格納
      let reservation3 = {};
      reservations.forEach((reservation: any, index: number) => {
        const viewIndex = index + shift;

        if (viewIndex < 5) {
          // 同時刻4つ目まで
          reservation.width = Math.round(this.x / 5);
          reservation.height = Math.round((this.y * 4) / (60 / course.duration_minute));
          if (viewIndex) {
            reservation.positionX = Math.round(this.x / 5) * viewIndex;
          } else {
            reservation.positionX = shift ? Math.round(this.x / 5) * viewIndex : 0;
          }
          reservation.positionY = Math.round(this.y * yIndex);
          if (viewIndex === 4) {
            // 3つ目の予約は一旦保持→それ以降の予約を格納するため
            reservation3 = reservation;
          } else {
            this.reservationsWithXy.push(reservation);
          }
        } else {
          // 同時刻4つ目以降
          overflowReservations.push(reservation);
        }
      });
      this.reservationsWithXy.push({ ...reservation3, overflowReservations: overflowReservations });
    }
  }
  private generateSpecialReservationView(reservations: ReservationData[], yIndex: number) {
    if (reservations.length === 1) {
      // 予約が同時刻1つの場合
      reservations.forEach((reservation: any) => {
        const endTimeValue =
          moment(reservation.datetime).hour() * 4 * 15 +
          moment(reservation.datetime).minute() +
          reservation.duration_min;
        reservation.width = Math.round(this.x / 5);
        reservation.positionX = 0;
        reservation.positionY = Math.round(this.y * yIndex);
        reservation.height = ((endTimeValue - yIndex * 15) / 15) * this.y;
        this.reservationsWithXy.push(reservation);
      });
    } else if (reservations.length > 1) {
      reservations.sort((a: any, b: any) => {
        if (a.duration_min < b.duration_min) return 1;
        if (a.duration_min > b.duration_min) return -1;
        return 0;
      });
      // 予約が同時刻2つ以上の場合
      const overflowReservations: any[] = []; // 1つ目以降の予約を格納
      let firstReservation = {};
      reservations.forEach((reservation: any, index: number) => {
        const endTimeValue =
          moment(reservation.datetime).hour() * 4 * 15 +
          moment(reservation.datetime).minute() +
          reservation.duration_min;
        if (index === 0) {
          reservation.width = Math.round(this.x / 5);
          reservation.positionX = 0;
          reservation.positionY = Math.round(this.y * yIndex);
          reservation.height = ((endTimeValue - yIndex * 15) / 15) * this.y;
          firstReservation = reservation;
        } else {
          // 同時刻1つ目以降
          overflowReservations.push(reservation);
        }
      });
      this.reservationsWithXy.push({ ...firstReservation, overflowReservations: overflowReservations });
    }
  }
  private generateGroupReservationView(reservations: ReservationData[], yIndex: number, shift: boolean) {
    if (reservations.length === 1) {
      // 予約が同時刻1つの場合
      reservations.forEach((reservation: any) => {
        const endTimeValue =
          moment(reservation.datetime).hour() * 4 * 15 +
          moment(reservation.datetime).minute() +
          reservation.course.duration_minute;
        reservation.width = Math.round(this.x / 5);
        reservation.positionX = shift ? Math.round(this.x / 5) * 1 : 0;
        reservation.positionY = Math.round(this.y * yIndex);
        reservation.height = ((endTimeValue - yIndex * 15) / 15) * this.y;
        this.reservationsWithXy.push(reservation);
      });
    } else if (reservations.length > 1) {
      reservations.sort((a: any, b: any) => {
        if (a.course.duration_minute < b.course.duration_minute) return 1;
        if (a.course.duration_minute > b.course.duration_minute) return -1;
        return 0;
      });
      // 予約が同時刻2つ以上の場合
      const overflowReservations: any[] = []; // 1つ目以降の予約を格納
      let firstReservation = {};
      reservations.forEach((reservation: any, index: number) => {
        const endTimeValue =
          moment(reservation.datetime).hour() * 4 * 15 +
          moment(reservation.datetime).minute() +
          reservation.course.duration_minute;
        if (index === 0) {
          reservation.width = Math.round(this.x / 5);
          reservation.positionX = shift ? Math.round(this.x / 5) * 1 : 0;
          reservation.positionY = Math.round(this.y * yIndex);
          reservation.height = ((endTimeValue - yIndex * 15) / 15) * this.y;
          firstReservation = reservation;
        } else {
          // 同時刻1つ目以降
          overflowReservations.push(reservation);
        }
      });
      this.reservationsWithXy.push({ ...firstReservation, overflowReservations: overflowReservations });
    }
  }
  // 特別予約で時間枠が被っているものを整理
  //this.reservationsWithXyの中の特別予約だけは各予約の開始日時が他の特別予約の枠の中に入っていたらその予約を他の特別予約のoverflowReservationsに入れる
  private arrangeSpecialReservation() {
    const specialReservations = _.cloneDeep(this.reservationsWithXy).filter(
      (reservation: any) => reservation.data_type === "reservation_special"
    );
    // specialReservationsをduration_minが長い順に並び替える
    specialReservations.sort((a: any, b: any) => {
      if (a.duration_min < b.duration_min) return 1;
      if (a.duration_min > b.duration_min) return -1;
      return 0;
    });
    // specialReservationsの中の予約を比較して、同じcourseIndexかつ開始日時が比較元の特別予約の枠の中に入っていたらその予約をoverflowReservationsに取り込んでspecialReservationsから削除する
    specialReservations.forEach((reservation: any, index: number) => {
      const overflowReservations: any[] = [];
      const startDateTime = new Date(reservation.datetime).getTime();
      const endDateTime = new Date(
        moment(reservation.datetime).add(reservation.duration_min, "minute").format("YYYY-MM-DD HH:mm")
      ).getTime();
      specialReservations.forEach((compareReservation: any, compareIndex: number) => {
        if (reservation.courseIndex !== compareReservation.courseIndex) return;
        const compareStartDateTime = new Date(compareReservation.datetime).getTime();
        const compareEndDateTime = new Date(
          moment(compareReservation.datetime).add(compareReservation.duration_min, "minute").format("YYYY-MM-DD HH:mm")
        ).getTime();
        if (index !== compareIndex) {
          if (startDateTime <= compareStartDateTime && endDateTime >= compareEndDateTime) {
            overflowReservations.push(compareReservation);
          }
        }
      });
      if (overflowReservations.length > 0) {
        reservation.overflowReservations = reservation.overflowReservations
          ? [...reservation.overflowReservations, ...overflowReservations]
          : overflowReservations;
        overflowReservations.forEach((overflowReservation: any) => {
          const index = specialReservations.findIndex(
            (specialReservation: any) => specialReservation.id === overflowReservation.id
          );
          specialReservations.splice(index, 1);
        });
        reservation.overflowReservations.sort((a: any, b: any) => {
          if (a.datetime < b.datetime) return -1;
          if (a.datetime > b.datetime) return 1;
          return 0;
        });
      }
    });
    // reservationsWithXyから特別予約を削除して、specialReservationsをreservationsWithXyに追加する
    this.reservationsWithXy = this.reservationsWithXy.filter(
      (reservation: any) => reservation.data_type !== "reservation_special"
    );
    this.reservationsWithXy = this.reservationsWithXy.concat(specialReservations);
    this.reservationsWithXy.sort((a: any, b: any) => {
      if (a.datetime < b.datetime) return -1;
      if (a.datetime > b.datetime) return 1;
      return 0;
    });
  }
  private arrangeGroupReservation() {
    const groupReservations = _.cloneDeep(this.reservationsWithXy).filter(
      (reservation: any) => reservation.data_type === "reservation_group"
    );
    // groupReservationsをduration_minが長い順に並び替える
    groupReservations.sort((a: any, b: any) => {
      if (a.course.duration_minute < b.course.duration_minute) return 1;
      if (a.course.duration_minute > b.course.duration_minute) return -1;
      return 0;
    });
    // groupReservationsの中の予約を比較して、同じcourseIndexかつ開始日時が比較元の特別予約の枠の中に入っていたらその予約をoverflowReservationsに取り込んでgroupReservationsから削除する
    groupReservations.forEach((reservation: any, index: number) => {
      const overflowReservations: any[] = [];
      const startDateTime = new Date(reservation.datetime).getTime();
      const endDateTime = new Date(
        moment(reservation.datetime).add(reservation.course.duration_minute, "minute").format("YYYY-MM-DD HH:mm")
      ).getTime();
      groupReservations.forEach((compareReservation: any, compareIndex: number) => {
        const compareStartDateTime = new Date(compareReservation.datetime).getTime();
        const compareEndDateTime = new Date(
          moment(compareReservation.datetime)
            .add(compareReservation.course.duration_minute, "minute")
            .format("YYYY-MM-DD HH:mm")
        ).getTime();
        if (index !== compareIndex) {
          if (startDateTime <= compareStartDateTime && endDateTime >= compareEndDateTime) {
            overflowReservations.push(compareReservation);
          }
        }
      });
      if (overflowReservations.length > 0) {
        reservation.overflowReservations = reservation.overflowReservations
          ? [...reservation.overflowReservations, ...overflowReservations]
          : overflowReservations;
        overflowReservations.forEach((overflowReservation: any) => {
          const index = groupReservations.findIndex(
            (specialReservation: any) => specialReservation.id === overflowReservation.id
          );
          groupReservations.splice(index, 1);
        });
        reservation.overflowReservations.sort((a: any, b: any) => {
          if (a.datetime < b.datetime) return -1;
          if (a.datetime > b.datetime) return 1;
          return 0;
        });
      }
    });
    // reservationsWithXyから特別予約を削除して、groupReservationsをreservationsWithXyに追加する
    this.reservationsWithXy = this.reservationsWithXy.filter(
      (reservation: any) => reservation.data_type !== "reservation_group"
    );
    this.reservationsWithXy = this.reservationsWithXy.concat(groupReservations);
    this.reservationsWithXy.sort((a: any, b: any) => {
      if (a.datetime < b.datetime) return -1;
      if (a.datetime > b.datetime) return 1;
      return 0;
    });
  }

  @Watch("currentDate")
  private async getReservations() {
    this.isLoading = true;
    this.setDayViewBusinessHour();
    this.reservationsWithXy = [];
    let groupReservations: ReservationData[] = [];
    let groupCourseIds: string[] = [];
    const courseGroupsRes = await FacilityService.getCourseGroups(this.hospitalId);
    const courseGroups = courseGroupsRes.data.data;
    // courseGroups(配列)の中にあるcourse_idsに選択中のコースIDがあればそのコースグループを取得
    const currentCourseGroup = courseGroups.find((courseGroup: any) =>
      courseGroup.course_ids?.includes(this.course.id)
    );
    if (currentCourseGroup) {
      // コースグループの中にあるコースIDから現在のコースIDを除外
      groupCourseIds = currentCourseGroup.course_ids?.filter((courseId: string) => courseId !== this.course.id);
    }
    try {
      const year = this.currentDate
        ? String(new Date(this.currentDate).getFullYear())
        : String(new Date().getFullYear()); // カレンダーの年取得
      const month = this.currentDate
        ? String(new Date(this.currentDate).getMonth() + 1)
        : String(new Date().getMonth() + 1); // カレンダーの月取得
      const viewDate = this.currentDate ? new Date(this.currentDate) : new Date();
      const date = String(viewDate.getDate());

      const res = (
        await FacilityService.getReservations(this.hospitalId, {
          year,
          month,
          date: date,
          course_id: this.course.id,
        })
      ).data.data;
      await asyncForEach(groupCourseIds, async (courseId: string) => {
        const res = await FacilityService.getReservations(this.hospitalId, {
          year,
          month,
          date: date,
          course_id: courseId,
        });
        groupReservations = [...groupReservations, ...res.data.data];
      });
      const todayReservations = res.filter(
        (reservation: ReservationData) =>
          moment(reservation.datetime).format("YYYY-MM-DD") === moment(viewDate).format("YYYY-MM-DD")
      );
      const todayGroupReservations = groupReservations
        .filter(
          (reservation: ReservationData) =>
            moment(reservation.datetime).format("YYYY-MM-DD") === moment(viewDate).format("YYYY-MM-DD") &&
            reservation.data_type === "reservation"
        )
        .map((reservation: ReservationData) => {
          reservation.data_type = "reservation_group";
          return reservation;
        });
      const todayReservationsWithGroup = [...todayReservations, ...todayGroupReservations];

      const timelineReservations: any = [];
      for (let i = 0; i < 1440; i += 15) {
        // 時間枠分の予約を入れる配列作成
        timelineReservations.push({ special: [], group: [], normal: [] });
      }
      if (todayReservationsWithGroup.length) {
        await asyncForEach(todayReservationsWithGroup, async (reservation: ReservationData) => {
          // 予約の詳細情報を追加 -開始
          const reservationWithDetail = {
            ...reservation,
            course: this.course,
          };
          if (reservation.data_type === "reservation_group") {
            const courseRes = await FacilityService.getCourseDetail(this.hospitalId, reservation.course_id);
            reservationWithDetail.course = courseRes.data.data[0];
          }

          this.outputCsvReservations.push(reservationWithDetail);
          // 予約の詳細情報を追加 -終了
          const hour = new Date(reservation.datetime).getHours(); //予約の時間h
          const minute = new Date(reservation.datetime).getMinutes(); //予約の時間m
          if (reservation.data_type === "reservation") {
            const timelineIndex = (hour * 60 + minute) / 15;
            timelineReservations[timelineIndex].normal.push(reservationWithDetail);
          } else if (reservation.data_type === "reservation_group") {
            // コースグループの予約の場合
            // 予約時間を分に変換してコースの感覚で割ってインデックスを取得→小数点切り捨て
            const timelineIndex = this.getSpecialReservationTime(reservation) / 15;
            timelineReservations[Math.floor(timelineIndex)].group.push({
              ...reservationWithDetail,
              startMinute: minute,
            });
          } else {
            // 特別予約の場合
            // 予約時間を分に変換してコースの感覚で割ってインデックスを取得→小数点切り捨て
            const timelineIndex = this.getSpecialReservationTime(reservation) / 15;
            timelineReservations[Math.floor(timelineIndex)].special.push({
              ...reservationWithDetail,
              startMinute: minute,
            });
          }
        });
      }
      const haveSpecialReservationIndex: number[] = [];
      const haveGroupReservationIndex: number[] = [];

      await timelineReservations.forEach(
        (
          reservations: { special: ReservationData[]; group: ReservationData[]; normal: ReservationData[] },
          index: number
        ) => {
          const yIndex = index;
          if (reservations.special.length) {
            reservations.special.forEach((reservation: any) => {
              const startIndex = Math.floor(this.getSpecialReservationTime(reservation) / 15);
              const endIndex = startIndex + Math.floor(reservation.duration_min / 15) - 1;
              for (let i = startIndex; i <= endIndex; i++) {
                haveSpecialReservationIndex.push(i);
              }
            });
          }
          if (reservations.group.length) {
            reservations.group.forEach((reservation: any) => {
              const startIndex = Math.floor(this.getSpecialReservationTime(reservation) / 15);
              const endIndex = startIndex + Math.floor(reservation.course.duration_minute / 15) - 1;
              for (let i = startIndex; i <= endIndex; i++) {
                haveGroupReservationIndex.push(i);
              }
            });
          }
          if (reservations.normal.length || reservations.special.length || reservations.group.length) {
            let hasSpecialDuplicateTimeLength = 0;
            let hasGroupDuplicateTimeLength = 0;
            for (let i = 0; i < this.course.duration_minute / 15; i++) {
              if (timelineReservations[index + i].special.length) {
                hasSpecialDuplicateTimeLength++;
              }
              if (timelineReservations[index + i].group.length) {
                hasGroupDuplicateTimeLength++;
              }
            }

            if (
              (haveSpecialReservationIndex.includes(index) && haveGroupReservationIndex.includes(index)) ||
              (hasSpecialDuplicateTimeLength && hasGroupDuplicateTimeLength)
            ) {
              // 同時刻帯に特別予約とコースグループ予約がある場合
              const normalLength = reservations.normal.length + 1;
              if (normalLength) {
                this.generateNormalReservationView(reservations.normal, this.course, yIndex, 2);
              }
              this.generateSpecialReservationView(reservations.special, yIndex);
              this.generateGroupReservationView(reservations.group, yIndex, true);
            } else if (haveSpecialReservationIndex.includes(index) || hasSpecialDuplicateTimeLength) {
              // 同時刻帯に特別予約がある場合
              const normalLength = reservations.normal.length + 1;
              if (normalLength) {
                this.generateNormalReservationView(reservations.normal, this.course, yIndex, 1);
              }
              this.generateSpecialReservationView(reservations.special, yIndex);
            } else if (haveGroupReservationIndex.includes(index) || hasGroupDuplicateTimeLength) {
              // 同時刻帯にグループ予約がある場合
              const normalLength = reservations.normal.length + 1;
              if (normalLength) {
                this.generateNormalReservationView(reservations.normal, this.course, yIndex, 1);
              }
              this.generateGroupReservationView(reservations.group, yIndex, false);
            } else {
              // 同時刻帯に特別予約がない場合
              const normalLength = reservations.normal.length;
              if (normalLength) {
                this.generateNormalReservationView(reservations.normal, this.course, yIndex, 0);
              }
            }
          }
        }
      );
      await this.arrangeSpecialReservation();
      this.arrangeGroupReservation();
      // await timelineReservations.forEach((reservations: ReservationData[], timelineIndex: number) => {
      //   const yIndex = timelineIndex;
      //   if (reservations.length && reservations.length === 1) {
      //     // 予約が同時刻1つの場合
      //     reservations.forEach((reservation: any) => {
      //       reservation.width = this.x;
      //       reservation.height = Math.round((this.y * 4) / (60 / this.course.duration_minute));
      //       reservation.positionX = 0;
      //       reservation.positionY = Math.round(this.y * (yIndex * (this.course.duration_minute / 15)));
      //       this.reservationsWithXy.push(reservation);
      //     });
      //   } else if (reservations && 1 < reservations.length && reservations.length < 5) {
      //     // 予約が同時刻2つ以上の場合
      //     reservations.forEach((reservation: any, index: number) => {
      //       if (index < 4) {
      //         reservation.width = Math.round(this.x / 4);
      //         reservation.height = Math.round((this.y * 4) / (60 / this.course.duration_minute));
      //         reservation.positionX = Math.round((this.x / 4) * index);
      //         reservation.positionY = Math.round(this.y * (yIndex * (this.course.duration_minute / 15)));
      //         this.reservationsWithXy.push(reservation);
      //       }
      //     });
      //   } else if (reservations.length && 4 < reservations.length) {
      //     // 予約が同時刻5つ以上の場合
      //     const overflowReservations: any[] = []; // 4つ目以降の予約を格納
      //     let reservation4 = {};
      //     reservations.forEach((reservation: any, index: number) => {
      //       if (index < 4) {
      //         // 同時刻4つ目まで
      //         if (index < 4) {
      //           reservation.width = Math.round(this.x / 4);
      //           reservation.height = Math.round((this.y * 4) / (60 / this.course.duration_minute));
      //           reservation.positionX = Math.round((this.x / 4) * index);
      //           reservation.positionY = Math.round(this.y * (yIndex * (this.course.duration_minute / 15)));
      //           this.reservationsWithXy.push(reservation);
      //         }
      //         if (index === 3) {
      //           // 4つ目の予約は一旦保持→それ以降の予約を格納するため
      //           reservation4 = reservation;
      //         } else {
      //           this.reservationsWithXy.push(reservation);
      //         }
      //       } else {
      //         // 同時刻4つ目以降
      //         overflowReservations.push(reservation);
      //       }
      //     });
      //     this.reservationsWithXy.push({ ...reservation4, overflowReservations: overflowReservations });
      //   }
      // });
      this.isLoading = false;
      this.isCreatedReservationsData = true;
      this.$emit("outputCsvReservations", this.outputCsvReservations);
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }
  }
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }
}
