import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { WeeklyEvent, MonthlyEvent, DayEvent, BusinessHours } from "@/@types/event";
import { ReservationData } from "@/@types/reservation";
import { CourseData } from "@/@types/course";

interface ICalendarModule {
  _currentEvents: WeeklyEvent[] | MonthlyEvent[] | ReservationData[];
  _reservations: ReservationData[];
  _weeklyEvents: WeeklyEvent[];
  _monthlyEvents: MonthlyEvent[];
  _businessHours: BusinessHours[];
  _selectedTargetTime: Date | null;
  _selectedCourse: CourseData | null;
  _isReloaded: boolean;
  _selectedInitialView: string;
  _currentDate: string;
  _viewCourseIndex: number;
}

@Module({
  dynamic: true,
  store,
  name: "calendar",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).calendar,
})
class CalendarModule extends VuexModule implements ICalendarModule {
  public _currentEvents: WeeklyEvent[] | MonthlyEvent[] | ReservationData[] = [];
  public _reservations: ReservationData[] = [];
  public _weeklyEvents: WeeklyEvent[] = [];
  public _monthlyEvents: MonthlyEvent[] = [];
  public _businessHours: BusinessHours[] = [];
  public _selectedTargetTime: Date | null = null;
  public _selectedCourse: CourseData | null = null;
  public _isReloaded = false;
  public _selectedInitialView = "timeGridDay";
  public _currentDate = "";
  public _viewCourseIndex = 0;

  /**
   * コース変更
   * @param course
   */
  @Mutation
  public changeSelectedCourse(course: CourseData) {
    this._selectedCourse = course;
  }

  /**
   * 表示コース開始位置変更
   *
   */
  @Mutation
  public changeViewCourseIndex(index: number) {
    this._viewCourseIndex = index;
  }

  /**
   * 選択日付変更
   * @param date
   */
  @Mutation
  public changeCurrentDate(date: string) {
    this._currentDate = date;
  }

  /**
   * 選択日付変更
   * @param value
   */
  @Mutation
  public changeIsReloaded(value: boolean) {
    this._isReloaded = value;
  }

  /**
   * カレンダーのview変更
   * @param course
   */
  @Mutation
  public changeSelectedInitialView(view: string) {
    this._selectedInitialView = view;
  }

  /**
   * 予約追加
   * @param reservations
   */
  @Mutation
  public changeReservations(reservations: ReservationData[]) {
    this._reservations = reservations;
  }

  /**
   * Weekly変更
   * @param weeklyEvent
   */
  @Mutation
  public weeklyAdd(weeklyEvent: WeeklyEvent[]) {
    this._weeklyEvents = weeklyEvent;
  }

  /**
   * Monthly追加
   * @param monthlyEvent
   */
  @Mutation
  public monthlyAdd(monthlyEvent: MonthlyEvent[]) {
    this._monthlyEvents = monthlyEvent;
  }

  /**
   * BusinessHours追加
   * @param businessHours
   */
  @Mutation
  public changeBusinessHours(businessHours: BusinessHours[]) {
    this._businessHours = businessHours;
  }

  /**
   * Monthlyリセット
   *
   */
  @Mutation
  public monthlyReset() {
    this._monthlyEvents = [];
  }

  @Mutation
  public changeHospitalReset() {
    this._currentEvents = [];
    this._reservations = [];
    this._weeklyEvents = [];
    this._monthlyEvents = [];
    this._businessHours = [];
    this._selectedTargetTime = null;
    this._selectedCourse = null;
    this._viewCourseIndex = 0;
  }

  /**
   * 変更
   * @param name
   */
  @Mutation
  public changeCurrent(name: string) {
    if (name === "day") {
      this._currentEvents = this._reservations;
    } else if (name === "weekly") {
      this._currentEvents = this._weeklyEvents;
    } else if (name === "monthly") {
      this._currentEvents = this._monthlyEvents;
    }
  }

  /**
   * 選択中の予約セット
   * @param time
   */
  @Mutation
  public selectedDailyEvent(time: Date) {
    this._selectedTargetTime = time;
  }

  /**
   * 予約メモ変更
   * @param start
   * @param memo
   */
  @Mutation
  public changeReservationMemo(reservation: DayEvent) {
    // const index = this._reservations.findIndex((event) => event.start === reservation.start);
    // this._reservations[index].description = reservation.description;
  }

  /**
   * 予約削除
   */
  @Mutation
  public deleteReservation() {
    // const target = this._reservations.find((event: DayEvent) =>
    //   moment(event.start).isSame(moment(this._selectedTargetTime))
    // );
    // if (target) {
    //   const index = this._reservations.findIndex((event: DayEvent) => event === target);
    //   this._reservations.splice(index, 1);
    //   this._currentEvents = this._reservations;
    // }
  }

  public get currentEvents() {
    return this._currentEvents || [];
  }
  public get reservations() {
    return this._reservations;
  }
  public get weeklyEvents() {
    return this._weeklyEvents || [];
  }
  public get monthlyEvents() {
    return this._monthlyEvents || [];
  }
  public get businessHours() {
    return this._businessHours || [];
  }
  public get isReloaded() {
    return this._isReloaded || false;
  }
  public get selectedTargetTime() {
    return this._selectedTargetTime || "";
  }
  public get selectedCourse() {
    return this._selectedCourse || null;
  }
  public get selectedInitialView() {
    return this._selectedInitialView || "timeGridDay";
  }
  public get currentDate() {
    return this._currentDate;
  }

  public get viewCourseIndex() {
    return this._viewCourseIndex;
  }
}
export default getModule(CalendarModule);
