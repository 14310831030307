
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FormSet extends Vue {
  @Prop({ type: [Number, String], default: 4 })
  private readonly colsLeft?: number | string;

  @Prop({ type: [Number, String], default: 8 })
  private readonly colsRight?: number | string;

  @Prop({ type: [Number, String], default: 8 })
  private readonly mb?: number | string;

  @Prop({ type: String, default: "" })
  private readonly labelFor?: string;

  @Prop({ type: String })
  private readonly label!: string;

  @Prop({ type: String, default: "" })
  private readonly labelClass?: string;

  @Prop({ type: String })
  private readonly labelStyle?: "default" | "hasBG" | "optional" | "required";

  @Prop({ type: String, default: "center" })
  private readonly labelAlign?: "left" | "center" | "right";
}
